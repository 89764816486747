$(function () {
    let firstTimeMenuChangeBig = true,
        firstTimeMenuChangeSmall = true;
    const $button = $('.js-btn-menu-mobile');
    const $search = $('.c-search-header');
    const $navigation = $('.c-navigation__nav');
    const $body = $('html, body');

    $(window).on('load resize orientationchange', function () {
        if (firstTimeMenuChangeBig && $(window).width() > 991) {
            firstTimeMenuChangeSmall = true;
            firstTimeMenuChangeBig = false;
            $('#menu').removeAttr('aria-hidden').show().off('keydown.aria.btnMenuMobile');
            $button.attr('aria-expanded', 'true');
            $body.off('click.aria.btnMenuMobile');
            $navigation.appendTo('.js-nav-desktop');
            $search.appendTo('.js-search-desktop');
            $body.css('overflow', '');
        } else if (firstTimeMenuChangeSmall && $(window).width() <= 991) {
            firstTimeMenuChangeSmall = false;
            firstTimeMenuChangeBig = true;
            $('.js-btn-menu-mobile[aria-expanded="true"]').trigger('click', [true]);
            $navigation.appendTo('.js-nav-mobile');
            $search.appendTo('.js-search-mobile');
        }
    });

    // On récupère la position basse de chaque entrée de menu de niveau 1 pour positionner le sous-menu associé
    const navLinks = document.querySelectorAll('.js-nav-link');
    const navSubmenus = document.querySelectorAll('.c-navigation__submenu');

    navLinks.forEach((navLink) => {
        setBottomElementPosition(navLink);

        navLink.addEventListener('click', () => {
            setBottomElementPosition(navLink);
        });
    });

    function setBottomElementPosition(el) {
        const adjacentSubmenu = el.parentElement.querySelector('.c-navigation__submenu');
        // Si la variable CSS --pseudo-menu-height existe, on prend sa valeur, sinon c'est 0
        const pseudoHeight = getComputedStyle(document.documentElement).getPropertyValue('--pseudo-menu-height') ?? 0;
        // Le code regex extrait les chiffres d'une chaine de caractères. Ex: '12px' => 12
        const pseudoHeightNumber = Number(pseudoHeight.replace(/[^0-9]/g, ''));
        // On ajoute le style top au sous-menu adjacent avec pour valeur la position basse du lien cliqué + 20 + la hauteur du potentiel menu pseudo
        adjacentSubmenu.style.top = el.getBoundingClientRect().bottom + 20 - pseudoHeightNumber + 'px';
    }
});
