/*
 * Auteur : Gagik Sargsyan
 */
const BackToTop = document.querySelector('.js-back-to-top');
window.addEventListener('scroll', () => {
    if ((document.querySelector('html').scrollTop) > 30) {
        BackToTop.classList.add('is-visible')
    } else {
        BackToTop.classList.remove('is-visible')
    }
});
